<template>
  <div class="d-flex">

    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Space</div>
      <v-autocomplete
        v-model="spacesFilter"
        :items="getSpaces"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        multiple
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-space"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.spaces)"
        @update:search-input="highlightSelection($event, getSpaces, $config.dashboardConfig.filter_slugs.spaces)"
        placeholder="Select Space"
        class="filter-select filter-select-space"
      >
        <template #prepend-item>
          <div class="px-4">
            <v-chip
              v-for="chip in selectedSpaces"
              class="filter-chip ml-0 mr-1 px-1"
              :key="chip.id"
            >
              <template #default>
                <div class="d-flex align-center max-width-100">
                  <div class="text-captions-2 accent-100--text text-truncate">{{chip.title}}</div>
                  <v-btn
                    class="ml-1"
                    height="12"
                    icon
                    width="12"
                    @click="removeFilter('spacesFilter', $config.dashboardConfig.filter_slugs.spaces, chip.id)"
                  >
                    <IconCancel width="10" height="10" class="accent-100--text" />
                  </v-btn>
                </div>
              </template>
            </v-chip>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-icon class="d-flex align-center mt-0 mb-0 list-item-icon">
                <v-sheet rounded="circle" :color="item.color" width="14" height="14"></v-sheet>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="d-flex text-captions-1 fill-width ">
                  <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate "></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template v-slot:selection="{}">
          <div class="selected-result d-flex align-center text-captions-1">
            Spaces <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{spacesFilter.length}}</div>
          </div>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition" style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Tags</div>
      <v-autocomplete
        v-model="tagsFilter"
        :items="filtersState[$config.dashboardConfig.filter_slugs.tags].data"
        :loading="filtersState[$config.dashboardConfig.filter_slugs.tags].loading"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        multiple
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-tags"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.tags)"
        @update:search-input="highlightSelection($event, filtersState[$config.dashboardConfig.filter_slugs.tags].data, $config.dashboardConfig.filter_slugs.tags)"
        placeholder="Select Tags"
        class="filter-select filter-select-tags"
      >
        <template #prepend-item>
          <div class="px-4">
            <v-chip
              v-for="chip in selectedTags"
              :key="chip.id"
              class="filter-chip ml-0 mr-1 px-1"
            >
              <template #default>
                <div class="d-flex align-center max-width-100">
                  <div class="text-captions-2 accent-100--text text-truncate">{{chip.title}}</div>
                  <v-btn
                    class="ml-1"
                    height="12"
                    icon
                    width="12"
                    @click="removeFilter('tagsFilter', $config.dashboardConfig.filter_slugs.tags, chip.id)"
                  >
                    <IconCancel width="10" height="10" class="accent-100--text" />
                  </v-btn>
                </div>
              </template>
            </v-chip>
          </div>
        </template>
        <template v-slot:selection="{ }">
          <div class="selected-result d-flex align-center text-captions-1">
            Tags <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{tagsFilter.length}}</div>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex text-captions-1 fill-width">
                  <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition"  style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <div
            v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.dashboardConfig.filter_slugs.tags)"
            class="d-flex justify-center"
          >
            <v-progress-circular
              v-show="filtersState[$config.dashboardConfig.filter_slugs.tags].loading"
              :size="20"
              color="accent"
              indeterminate
            />
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Project</div>
      <v-autocomplete
        v-model="projectsFilter"
        :items="filtersState[$config.dashboardConfig.filter_slugs.projects].data"
        :loading="filtersState[$config.dashboardConfig.filter_slugs.projects].loading"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        multiple
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-projects"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.projects)"
        @update:search-input="highlightSelection($event, filtersState[$config.dashboardConfig.filter_slugs.projects].data, $config.dashboardConfig.filter_slugs.projects)"
        placeholder="Select Project"
        class="filter-select filter-select-projects"
      >
        <template #prepend-item>
          <div class="px-4">
            <v-chip
              v-for="chip in selectedProjects"
              :key="chip.id"
              class="filter-chip ml-0 mr-1 px-1"
            >
              <template #default>
                <div class="d-flex align-center max-width-100">
                  <div class="text-captions-2 accent-100--text text-truncate">{{chip.title}}</div>
                  <v-btn
                    class="ml-1"
                    height="12"
                    icon
                    width="12"
                    @click="removeFilter('projectsFilter', $config.dashboardConfig.filter_slugs.projects, chip.id)"
                  >
                    <IconCancel width="10" height="10" class="accent-100--text" />
                  </v-btn>
                </div>
              </template>
            </v-chip>
          </div>
        </template>
        <template v-slot:selection="{}">
          <div class="selected-result d-flex align-center text-captions-1">
            Projects <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{projectsFilter.length}}</div>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex text-captions-1 fill-width">
                  <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition"  style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <div
            v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.dashboardConfig.filter_slugs.projects)"
            class="d-flex justify-center"
          >
            <v-progress-circular
              v-show="filtersState[$config.dashboardConfig.filter_slugs.projects].loading"
              :size="20"
              color="accent"
              indeterminate
            />
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Asset Status</div>
      <v-autocomplete
        v-model="statusesFilter"
        :items="filtersState[$config.dashboardConfig.filter_slugs.statuses].data"
        :loading="filtersState[$config.dashboardConfig.filter_slugs.statuses].loading"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        multiple
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-statuses"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.statuses)"
        @update:search-input="highlightSelection($event, filtersState[$config.dashboardConfig.filter_slugs.statuses].data, $config.dashboardConfig.filter_slugs.statuses)"
        placeholder="Select Status"
        class="filter-select filter-select-statuses"
      >
        <template #prepend-item>
          <div class="px-4">
            <v-chip
              v-for="chip in selectedStatuses"
              :key="chip.id"
              class="filter-chip ml-0 mr-1 px-1"
            >
              <template #default>
                <div class="d-flex align-center max-width-100">
                  <div class="text-captions-2 accent-100--text text-truncate">{{chip.title}}</div>
                  <v-btn
                    class="ml-1"
                    height="12"
                    icon
                    width="12"
                    @click="removeFilter('statusesFilter', $config.dashboardConfig.filter_slugs.statuses, chip.id)"
                  >
                    <IconCancel width="10" height="10" class="accent-100--text" />
                  </v-btn>
                </div>
              </template>
            </v-chip>
          </div>
        </template>
        <template v-slot:selection="{}">
          <div class="selected-result d-flex align-center text-captions-1">
            Statuses <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{statusesFilter.length}}</div>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex text-captions-1 fill-width">
                  <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition"  style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <div
            v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.dashboardConfig.filter_slugs.statuses)"
            class="d-flex justify-center"
          >
            <v-progress-circular
              v-show="filtersState[$config.dashboardConfig.filter_slugs.statuses].loading"
              :size="20"
              color="accent"
              indeterminate
            />
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Module</div>
      <v-autocomplete
        v-model="modulesFilter"
        :items="filtersState[$config.dashboardConfig.filter_slugs.modules].data"
        :loading="filtersState[$config.dashboardConfig.filter_slugs.modules].loading"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        multiple
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-modules"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.modules)"
        @update:search-input="highlightSelection($event, filtersState[$config.dashboardConfig.filter_slugs.modules].data, $config.dashboardConfig.filter_slugs.modules)"
        placeholder="Select Module"
        class="filter-select filter-select-modules"
      >
        <template #prepend-item>
          <div class="px-4">
            <v-chip
              v-for="chip in modulesFilter"
              :key="chip"
              class="filter-chip ml-0 mr-1 px-1"
            >
              <template #default>
                <div class="d-flex align-center max-width-100">
                  <div class="text-captions-2 accent-100--text text-truncate">{{chip}}</div>
                  <v-btn
                    class="ml-1"
                    height="12"
                    icon
                    width="12"
                    @click="removeFilter('modulesFilter', $config.dashboardConfig.filter_slugs.modules, chip)"
                  >
                    <IconCancel width="10" height="10" class="accent-100--text" />
                  </v-btn>
                </div>
              </template>
            </v-chip>
          </div>
        </template>
        <template v-slot:selection="{}">
          <div class="selected-result d-flex align-center text-captions-1">
            Modules <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{modulesFilter.length}}</div>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex text-captions-1 fill-width">
                  <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition"  style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <div
            v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.dashboardConfig.filter_slugs.modules)"
            class="d-flex justify-center"
          >
            <v-progress-circular
              v-show="filtersState[$config.dashboardConfig.filter_slugs.modules].loading"
              :size="20"
              color="accent"
              indeterminate
            />
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="mr-3 select-wrapper">
      <div class="mb-1 gray-100--text text-captions-1">Date</div>
      <v-autocomplete
        v-model="dateFilter"
        :items="filtersState[$config.dashboardConfig.filter_slugs.date].data"
        :loading="filtersState[$config.dashboardConfig.filter_slugs.date].loading"
        :item-text="'title'"
        item-value="id"
        :return-object="false"
        :multiple="false"
        persistent-hint
        hide-details
        :hide-selected="false"
        dense
        outlined
        append-icon=""
        attach=".filter-select-date"
        :menu-props="{contentClass: 'filter-autocomplete-menu'}"
        @change="updateFilter($config.dashboardConfig.filter_slugs.date)"
        @update:search-input="highlightSelection($event, filtersState[$config.dashboardConfig.filter_slugs.date].data, $config.dashboardConfig.filter_slugs.date)"
        placeholder="Select Date"
        class="filter-select filter-select-date"
      >
        <template v-slot:selection="{}">
          <div class="selected-result d-flex align-center text-captions-1">
            Date <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">1</div>
          </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs" @click="onClickDateItem">
            <template #default="{active}">
              <v-list-item-action class="mt-2 mb-2">
                <UiCheckbox
                  checkbox-form="square"
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex text-captions-1">
                  <div v-html="item.htmlTitle || item.title"></div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template #append>
          <IconChevronDown width="16" class="icon-transition"  style="margin-top: -1px;"/>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <div
            v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.dashboardConfig.filter_slugs.date)"
            class="d-flex justify-center"
          >
            <v-progress-circular
              v-show="filtersState[$config.dashboardConfig.filter_slugs.date].loading"
              :size="20"
              color="accent"
              indeterminate
            />
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="mt-4">
      <UiBtn
        height="32"
        color="accent"
        class="font-weight-regular"
        text
        @click="clearAllFilters"

      >
        Clear all filters
      </UiBtn>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn.vue";
import UiCheckbox from "@/components/UI/UiCheckbox.vue";
import {highlightText} from "@/utils/helpers";

export default {
  name: "TableFilter",
  components: {
    UiCheckbox,
    UiBtn,
    // IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancel: () => import("@/components/icons/IconCancel")
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    filtersState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      debounce: null,
      debounceSearch: null,
    }
  },
  computed: {
    ...mapGetters([
      'getSpaces',
    ]),
    spacesFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.spaces]: val, [this.$config.dashboardConfig.filter_slugs.projects]: [], [this.$config.dashboardConfig.filter_slugs.modules]: [], [this.$config.dashboardConfig.filter_slugs.tags]: []}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.spaces]
      }
    },
    tagsFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.tags]: val}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.tags]
      }
    },
    projectsFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.projects]: val, [this.$config.dashboardConfig.filter_slugs.modules]: [], [this.$config.dashboardConfig.filter_slugs.tags]: []}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.projects]
      }
    },
    statusesFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.statuses]: val}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.statuses]
      }
    },
    modulesFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.modules]: val}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.modules]
      }
    },
    dateFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.dashboardConfig.filter_slugs.date]: val}})
      },
      get() {
        return this.filter[this.$config.dashboardConfig.filter_slugs.date]
      }
    },
    selectedSpaces() {
      return this.getSpaces.filter(el => this.spacesFilter.includes(el.id))
    },
    selectedTags() {
      return this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].data.filter(el => this.tagsFilter.includes(el.id))
    },
    selectedProjects() {
      return this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].data.filter(el => this.projectsFilter.includes(el.id))
    },
    selectedStatuses() {
      return this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].data.filter(el => this.statusesFilter.includes(el.id))
    }
  },
  mounted() {
  },
  methods: {
    updateFilter(type) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        if(this.$route.query.page && this.$route.query.page !== '1') {
          await this.$router.replace({query: {...this.$route.query, page: '1'}})
        }
        this.$emit('updateFilter', type)
      }, 800)
    },
    clearAllFilters() {
      // eslint-disable-next-line no-prototype-builtins
      if(Object.values(this.$config.dashboardConfig.filter_slugs).find(el => typeof this.$route.query[el] === 'number' || this.$route.query[el]?.length)) {
        this.$router.replace({query: {...this.$route.query, page: '1', [this.$config.dashboardConfig.filter_slugs.spaces]: [], [this.$config.dashboardConfig.filter_slugs.projects]: [], [this.$config.dashboardConfig.filter_slugs.statuses]: [], ['modules']: [], [this.$config.dashboardConfig.filter_slugs.tags]: [], [this.$config.dashboardConfig.filter_slugs.date]: undefined}})
        this.$emit('updateData')
        this.$emit('refetchFilters')
      }
    },
    endIntersect(entries, observer, isIntersecting, type) {
      if (isIntersecting && !this.filtersState[type].loading && this.filtersState[type].meta?.total > this.filtersState[type].data.length && !this.filtersState[type].search) {
        this.filtersState[type].page += 1
        this.$emit('loadMoreFilters', {type})
      }
    },
    highlightSelection($event, list, type) {
      this.filtersState[type].search = $event

      list.forEach(item => {
        item.htmlTitle = highlightText($event || '', item.title || '');
      });

      if($event) {
        clearTimeout(this.debounceSearch)
        this.debounceSearch = setTimeout(() => {
          this.$emit('loadMoreFilters', {type})
        }, 600)
      }
    },
    onClickDateItem() {
      if(this.dateFilter || this.dateFilter === 0) {
        this.dateFilter = undefined
        this.updateFilter(this.$config.dashboardConfig.filter_slugs.date)
      }
    },
    removeFilter(name, type, id) {
      this[name] = this[name].filter(item => item !== id)
      this.updateFilter(type)
    }
  }
}
</script>

<style scoped lang="scss">
.select-wrapper {
  max-width: 150px;
}
.selected-count {
  width: 20px;
  height: 20px;
  background-color: var(--v-gray-30-base);
  border-radius: 50%;
}
.selected-result {
  padding: 3px 0;
}
.filter-select {
  :deep(.v-select__selections) {
    padding: 2px 0 !important;
    .selected-result {
      &:not(:first-child) {
        display: none !important;
      }
    }
    input {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.list-item-icon {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100%;
}
</style>
